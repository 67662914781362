import "@aws-amplify/ui-react/styles.css";
import "./App.scss"
import "./scss/custom.scss"
import Home from "./components/home";
import {withAuthenticator} from "@aws-amplify/ui-react";

function App() {
  return (
      <Home/>
  );
}

export default withAuthenticator(App);