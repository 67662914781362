import {Form} from "react-bootstrap";

export default function PlayerPicker(props) {
  const {label, options, name, value, onChange, useRating, savedUserPicks} = props;

  function getRemainingPicks(player) {
    let remainingPicks = 5

    if (savedUserPicks !== undefined && player in savedUserPicks) {
      remainingPicks -= savedUserPicks[player]

      if (remainingPicks < 0) {
        remainingPicks = 0
      }
    }
      
    return remainingPicks;
  }

  function getDisabled(name, player) {
    if ( !name.includes("Lock") ) 
      return false;

    return getRemainingPicks(player) <= 0;
  }

  return (
      <>
        <Form.Label>{label}</Form.Label>
        <Form.Select name={name} value={value} onChange={onChange}>
          <option value={-1}>Select a Player</option>
          {options?.map((option, idx) => {
            return <option key={`${label}_player_${idx}`} disabled={getDisabled(name, option.id)} value={option.id}>{name.includes("Lock") ? option.name + " Remaining Picks: " + getRemainingPicks(option.id) : (useRating ? option.name + ' Rating: ' + option.rating : option.name + ' Avg: ' + option.currentAverage)}</option>
          })}
        </Form.Select>
      </>
  )
}